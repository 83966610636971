import React from 'react'
import Layout from '../components/Default/Layout'
import Meta from '../components/Default/Meta'

const DekujemeVam = () => {
  return (
    <Layout sClick={`var seznam_cId = 100069217; var seznam_value = 0;`}>
      <div className="DekujemeVam">
        <h1>Děkujeme za Váš dotaz</h1>
        <a href="/" className="Button">
          Úvodní stránka
        </a>
      </div>
    </Layout>
  )
}

export default DekujemeVam
